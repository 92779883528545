export default {
  BUTTON_SECONDARY_CLASS: 'button button--primary cm_primary',
  GO_TEXT: '<span>GO</span>',
  CLEAR_TEXT: '<span>Clear</span>',
  CHANGE_VEHICLE: '<span>Change Vehicle</span>',
  DISCARD_VEHICLE: '<span>Clear Selection</span>',
  START_OVER: '<span>Start over<span>',
  PRICE_FACET_GO_TEXT: '<span>GO</span>',
  FACET_DIALOG_CLEAR: '<span>Clear</span>',
  FACET_DIALOG_DONE: '<span>Done</span>',
  FILTERS_TEXT: '<span>Filters</span>',
  ADD_VEHICLE: '<span>Add a vehicle</span>',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',
};
